import {
    CloseOutlined,
    DownloadOutlined,
    SettingOutlined,
    UserAddOutlined,
} from "@ant-design/icons";
import {useGoogleLogin} from "@react-oauth/google";
import {Button, Card, Form, InputNumber, Modal, Select, Space, TimePicker, Typography} from "antd";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {
    createCalendarIntegration,
    deleteCalendarIntegration,
    getCalendarIntegration, updateCalendarIntegration,
} from "../../services/calendar-integration";
import MessageApiContext from "../../context/message-api";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export default function CalendarIntegration({icon, label, assistantID}) {
    const [configOpen, setConfigOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const messageApi = useContext(MessageApiContext);
    const [data, setData] = useState([]);

    const googleLogin = useGoogleLogin({
        onSuccess: (response) => {
            save(response?.code)
        },
        onNonOAuthError: (nonOAuthError) => {
            if (nonOAuthError.type === 'popup_closed')
                messageApi.info("Janela de autenticação fechada, cancelado pelo usuário.");
            if (nonOAuthError.type === 'popup_failed_to_open')
                messageApi.warn("Falha ao abrir popup, verifique a configurações de popup do seu navegador.");

            setButtonLoading(false);
        },
        onError: (error) => {
            console.error("Erro:", {error});
            messageApi.error("Erro ao instalar google calendar, tente novamente.");
            setButtonLoading(false);
        },
        scope: "https://www.googleapis.com/auth/calendar.events.owned https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar.freebusy https://www.googleapis.com/auth/calendar.settings.readonly",
        flow: "auth-code",
    })

    const login = useCallback(() => {
        setButtonLoading(true);
        googleLogin();
    }, [googleLogin, setButtonLoading])

    const handleInstall = useCallback(() => {
        setButtonLoading(true);
        login();
    }, [login])

    const handleUninstallAll = useCallback(() => {
        // handle uninstall all
    }, [])

    const reload = useCallback(() => {
        getCalendarIntegration({assistantID})
            .then(({data}) => {
                setData(data);
            })
            .catch(() => {
                messageApi.error("Erro ao carregar integrações do Calendar.");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [assistantID])

    useEffect(() => {
        setLoading(true);
        reload()
    }, [assistantID, reload]);

    const save = useCallback((token) => {
        createCalendarIntegration({code: token, assistantID: Number(assistantID), active: true, redirectURI: window.location.origin})
            .then(({data}) => {
                messageApi.success("Google calendar instalado com sucesso!");
                setData(old => [...old, data]);
            })
            .catch(() => {
                messageApi.error("Erro ao salvar as integrações com google calendar");
            })
            .finally(() => {
                setButtonLoading(false);
            });
    }, [assistantID, createCalendarIntegration, setButtonLoading]);

    const hasData = useMemo(() => data?.length > 0, [data])

    return (
        <>
            <Configuration data={data}
                           messageApi={messageApi}
                           reload={reload}
                           buttonLoading={buttonLoading}
                           login={login}
                           open={configOpen}
                           setOpen={setConfigOpen}/>
            <Card
                loading={loading}
                title={
                    <Typography.Title level={5} ellipsis={{tooltip: label}}>
                        {icon} {label}
                    </Typography.Title>
                }
                actions={hasData ? [
                    <UserAddOutlined onClick={handleInstall}/>,
                    <SettingOutlined onClick={() => setConfigOpen(true)}/>,
                ] : null}
                extra={
                    <Button type={hasData ? 'default' : 'primary'}
                            loading={loading || buttonLoading}
                            danger={hasData}
                            disabled={data?.length > 1}
                            onClick={() => hasData ? handleUninstallAll() : handleInstall()}
                            icon={hasData ? <CloseOutlined/> : <DownloadOutlined/>}
                    >{loading ? undefined : hasData ? 'Remover' : 'Instalar'}</Button>
                }
            >
                {/*{hasData ?*/}
                {/*    <Row gutter={[8, 8]}>*/}
                {/*        {data.map((item, i) => <Col>*/}
                {/*                <Button danger*/}
                {/*                        loading={loading === item.id}*/}
                {/*                        onClick={() => handleUninstall(item.id)}*/}
                {/*                        icon={<DeleteOutlined/>}>Conta {i + 1}</Button>*/}
                {/*            </Col>*/}
                {/*        )}*/}

                {/*        <Col>*/}
                {/*    </Row> :*/}
                <Typography.Paragraph>Configure o google agenda.</Typography.Paragraph>

            </Card>
        </>
    );
}

const daysOfWeek = [
    {day: 0, label: "Dom"},
    {day: 1, label: "Seg"},
    {day: 2, label: "Ter"},
    {day: 3, label: "Qua"},
    {day: 4, label: "Qui"},
    {day: 5, label: "Sex"},
    {day: 6, label: "Sab"},
];

const cloneInitialSchedules = () => ({
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
});

const Configuration = ({open, setOpen, data, reload, messageApi, login, buttonLoading}) => {
    const [form] = Form.useForm();
    const [calendars, setCalendars] = useState([]);
    const [selectedCalendar, setSelectedCalendar] = useState(null);
    const [meetingDuration, setMeetingDuration] = useState(30);
    const [activeDay, setActiveDay] = useState(null);
    const [schedulesByCalendar, setSchedulesByCalendar] = useState({});
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (data && data.length > 0) {
            setCalendars(data);
        }
    }, [data]);

    useEffect(() => {
        if (open && calendars.length > 0 && !selectedCalendar) {
            const defaultCalendar = calendars[0];
            setSelectedCalendar(defaultCalendar);
            form.setFieldsValue({ calendar: defaultCalendar.id });
            // Se necessário, inicialize os schedules também:
            if (!schedulesByCalendar[defaultCalendar.id]) {
                const initialSchedules = cloneInitialSchedules();
                if (defaultCalendar.schedules && defaultCalendar.schedules.length > 0) {
                    defaultCalendar.schedules.forEach((schedule) => {
                        initialSchedules[schedule.day] = [
                            ...(initialSchedules[schedule.day] || []),
                            {
                                start: dayjs(schedule.startTime, "HH:mm:ss"),
                                end: dayjs(schedule.endTime, "HH:mm:ss"),
                            },
                        ];
                    });
                }
                setSchedulesByCalendar((prev) => ({
                    ...prev,
                    [defaultCalendar.id]: initialSchedules,
                }));
            }
        }
    }, [open, calendars, selectedCalendar, form]);

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setSelectedCalendar(null);
            setActiveDay(null);
            setSchedulesByCalendar({});
            setMeetingDuration(30);
        }
    }, [open]);

    const handleUninstall = useCallback((id) => {
        setLoading(true);
        deleteCalendarIntegration(id)
            .then(() => {
                reload();
                setOpen(false);
                setSelectedCalendar(null);
            })
            .catch(() => {
                messageApi.error("Erro ao deletar integração com google.");
            })
            .finally(() => {
                setLoading(null);
            });
    }, [reload, setOpen, messageApi, setLoading])

    const handleCalendarChange = (value) => {
        const calendar = calendars.find((item) => item.id === value);
        setSelectedCalendar(calendar);
        if (!schedulesByCalendar[calendar.id]) {
            const initialSchedules = cloneInitialSchedules();
            if (calendar.schedules && calendar.schedules.length > 0) {
                calendar.schedules.forEach((schedule) => {
                    initialSchedules[schedule.day] = [
                        ...(initialSchedules[schedule.day] || []),
                        {
                            start: dayjs(schedule.startTime, "HH:mm:ss"),
                            end: dayjs(schedule.endTime, "HH:mm:ss"),
                        },
                    ];
                });
            }
            setSchedulesByCalendar((prev) => ({ ...prev, [calendar.id]: initialSchedules }));
        }
    };

    const addSchedule = () => {
        if (activeDay === null || !selectedCalendar) return;
        setSchedulesByCalendar((prev) => {
            const current = prev[selectedCalendar.id]
                ? {...prev[selectedCalendar.id]}
                : cloneInitialSchedules();
            const newSchedule = {
                start: dayjs("08:30:00", "HH:mm:ss"),
                end: dayjs("18:30:00", "HH:mm:ss"),
            };
            current[activeDay] = [...current[activeDay], newSchedule];
            return {...prev, [selectedCalendar.id]: current};
        });
    };

    const removeSchedule = (day, index) => {
        if (!selectedCalendar) return;
        setSchedulesByCalendar((prev) => {
            const current = prev[selectedCalendar.id]
                ? {...prev[selectedCalendar.id]}
                : cloneInitialSchedules();
            current[day] = current[day].filter((_, i) => i !== index);
            return {...prev, [selectedCalendar.id]: current};
        });
    };

    const updateScheduleTime = (day, index, field, value) => {
        if (!selectedCalendar) return;
        setSchedulesByCalendar((prev) => {
            const current = prev[selectedCalendar.id]
                ? {...prev[selectedCalendar.id]}
                : cloneInitialSchedules();
            const daySchedules = [...current[day]];
            daySchedules[index] = {...daySchedules[index], [field]: value};
            current[day] = daySchedules;
            return {...prev, [selectedCalendar.id]: current};
        });
    };

    const replicateSchedules = () => {
        if (activeDay === null || !selectedCalendar) return;
        setSchedulesByCalendar((prev) => {
            const current = prev[selectedCalendar.id]
                ? {...prev[selectedCalendar.id]}
                : cloneInitialSchedules();
            const sourceSchedules = current[activeDay].map((sch) => ({
                start: sch.start.clone(),
                end: sch.end.clone(),
            }));
            for (let d = 0; d < 7; d++) {
                current[d] = sourceSchedules.map((s) => ({
                    start: s.start.clone(),
                    end: s.end.clone(),
                }));
            }
            return {...prev, [selectedCalendar.id]: current};
        });
    };

    const onFinish = () => {
        if (!selectedCalendar) return;
        const currentSchedules =
            schedulesByCalendar[selectedCalendar.id] || cloneInitialSchedules();
        const scheduleArray = [];
        Object.keys(currentSchedules).forEach((dayKey) => {
            currentSchedules[dayKey].forEach((item) => {
                scheduleArray.push({
                    day: Number(dayKey),
                    startTime: item.start.format("HH:mm:ss"),
                    endTime: item.end.format("HH:mm:ss"),
                });
            });
        });
        const payload = {
            assistantID: selectedCalendar.assistantID,
            token: selectedCalendar.token,
            duration: meetingDuration,
            schedules: scheduleArray,
        };

        setSaving(true);
        updateCalendarIntegration(selectedCalendar.id, {...selectedCalendar, ...payload}).then(({}) => {
            setOpen(false);
            messageApi.success("Atualizado com sucesso!");
            reload();
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao atualizar.");
        }).finally(() => {
            setSaving(false);
        });
    };

    const currentSchedules =
        selectedCalendar && schedulesByCalendar[selectedCalendar.id]
            ? schedulesByCalendar[selectedCalendar.id]
            : cloneInitialSchedules();

    return (
        <Modal
            open={open}
            okText="Atualizar"
            cancelText="Cancelar"
            onOk={form.submit}
            okButtonProps={{ loading: saving }}
            title="Configure seus horários"
            onCancel={() => setOpen(false)}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    label="Selecione a agenda"
                    name="calendar"
                    rules={[{ required: true, message: "Selecione uma agenda" }]}
                >
                    <Space>
                        <Select style={{ width: 200 }} onChange={(value) => {
                            form.setFieldsValue({ calendar: value });
                            handleCalendarChange(value);
                        }}>
                            {calendars.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.email}
                                </Select.Option>
                            ))}
                        </Select>
                        <Button type="primary" loading={buttonLoading} onClick={login}>
                            Nova Agenda
                        </Button>
                        <Button
                            type="default"
                            danger
                            loading={loading}
                            onClick={() => handleUninstall(selectedCalendar?.id)}
                        >
                            Remover
                        </Button>
                    </Space>
                </Form.Item>
                {selectedCalendar && <>
                    <Form.Item label="Duração da Reunião (minutos)">
                        <InputNumber min={1} value={meetingDuration} onChange={setMeetingDuration}/>
                    </Form.Item>
                    <Form.Item label="Dias">
                        <Space>
                            {daysOfWeek.map((d) => (
                                <Button
                                    key={d.day}
                                    type={activeDay === d.day ? "primary" : "default"}
                                    shape="circle"
                                    size="large"
                                    style={{width: "3.2rem", height: "3.2rem"}}
                                    onClick={() => setActiveDay(d.day)}
                                >
                                    {d.label}
                                </Button>
                            ))}
                        </Space>
                    </Form.Item>
                    {activeDay !== null && (
                        <Form.Item label={`Horários para ${daysOfWeek.find((d) => d.day === activeDay).label}`}>
                            <Space direction="vertical" style={{width: "100%"}}>
                                {currentSchedules[activeDay].map((sch, index) => (
                                    <Space key={index}>
                                        <TimePicker
                                            format="HH:mm:ss"
                                            value={sch.start}
                                            onChange={(value) => updateScheduleTime(activeDay, index, "start", value)}
                                        />
                                        <TimePicker
                                            format="HH:mm:ss"
                                            value={sch.end}
                                            onChange={(value) => updateScheduleTime(activeDay, index, "end", value)}
                                        />
                                        <Button danger onClick={() => removeSchedule(activeDay, index)}>
                                            Remover
                                        </Button>
                                    </Space>
                                ))}
                                <Space>
                                    <Button type="dashed" loading={buttonLoading} onClick={addSchedule}>
                                        Adicionar Horário
                                    </Button>
                                    <Button onClick={replicateSchedules}>
                                        Replicar para todos os dias
                                    </Button>
                                </Space>
                            </Space>
                        </Form.Item>
                    )}
                    <Form.Item style={{display: "none"}}>
                        <Button type="primary" htmlType="submit"/>
                    </Form.Item>
                </>}
            </Form>
        </Modal>
    );
};

