import {
    Button,
    Card,
    theme,
    Typography,
} from "antd";
import {
    CloseOutlined,
    DownloadOutlined,
} from "@ant-design/icons";
import React, {useCallback, useEffect, useState} from "react";
import {
    createChatInstance,
    deleteChatInstance,
    getChatInstance,
} from "../../services/chatIntegration";

export function ChatIntegration({icon, label, description, assistantID, messageApi}) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);

    const {
        token: {colorPrimary},
    } = theme.useToken();

    useEffect(() => {
        setLoading(true)
        getChatInstance(assistantID).then(({data}) => {
            if (data.length === 0)
                return

            setData(data[0])
        }).catch((error) => {
            console.log(error)
            messageApi.error("Erro ao buscar integração de chat.");
        }).finally(() => {
            setLoading(false)
        });
    }, [assistantID, messageApi]);

    const install = useCallback(() => {
        setButtonLoading(true)
        createChatInstance(assistantID).then(({data}) => {
            setData(data);
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao instalar integração de chat.");
        }).finally(() => {
            setButtonLoading(false);
        });
    }, [assistantID, messageApi]);

    const uninstall = useCallback(() => {
        setButtonLoading(true);
        deleteChatInstance(assistantID, data.id).then(() => {
            setData(undefined);
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao remover integração de chat.");
        }).finally(() => {
            setButtonLoading(false)
        });
    }, [data, messageApi]);


    return (
        <>
            <Card
                loading={loading}
                title={<Typography.Title level={5} ellipsis={{tooltip: label}}>{icon} {label}</Typography.Title>}
                extra={<Button type={data ? 'default' : 'primary'}
                               loading={loading || buttonLoading}
                               danger={!!data}
                               onClick={() => data ? uninstall() : install()}
                               icon={data ? <CloseOutlined/> : <DownloadOutlined/>}
                >{loading ? undefined : data ? 'Remover' : 'Instalar'}</Button>}>
                {description}
            </Card>
        </>
    )
}
