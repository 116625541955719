import {List} from "antd";
import {CalendarOutlined, GlobalOutlined, LinkOutlined, MessageOutlined, WhatsAppOutlined} from "@ant-design/icons";
import {RitaIntegration} from "../../../../components/rita-integration";
import React, {useContext} from "react";
import {WhatsappIntegration} from "../../../../components/whatsapp-integration";
import UserContext from "../../../../context/users";
import {AdminRole, EditorRole, OwnerRole} from "../../../../constants";
import {ZAPIIntegration} from "../../../../components/z-api-integration";
import MyZapIntegration from "../../../../components/my-zap-integration";
import {AgendorIntegration} from "../../../../components/agendor-integration";
import CalendarIntegration from "../../../../components/calendar-integration";
import EvolutionIntegration from "../../../../components/evolution-integration";
import {ChatIntegration} from "../../../../components/chat-integration";

const integrations = [
    {
        label: "Link externo",
        description: "Exponha seu assistente externamente",
        icon: <GlobalOutlined />,
        type: "rita",
        roles: [AdminRole, OwnerRole, EditorRole]
    },
    {
        label: "Whatsapp (Oficial)",
        description: "Publique seu chat no whatsapp",
        icon: <WhatsAppOutlined />,
        type: "whatsapp",
        roles: [AdminRole]
    },
    {
        label: "Whatsapp (Z-API)",
        description: "Publique seu chat no whatsapp",
        icon: <WhatsAppOutlined />,
        type: "z-api",
        roles: [AdminRole]
    },
    {
        label: "Whatsapp (MyZap)",
        description: "Publique seu chat no whatsapp",
        icon: <WhatsAppOutlined />,
        type: "myZap",
        roles: [AdminRole]
    },
    {
        label: "Agendor (Agendor)",
        description: "Realize integrações com seu CRM agendor",
        icon: <LinkOutlined />,
        type: "agendor",
        roles: [AdminRole, OwnerRole]
    },
    {
        label: "Whatsapp (Evolution-Api)",
        description: "Conecte seu whatsapp no assistente usando a evolution",
        icon: <WhatsAppOutlined />,
        type: "evolution",
        roles: [AdminRole, OwnerRole]
    },
    {
        label: "Agendamento (Google Agenda)",
        description: "Realize integração com o Google Agenda",
        icon: <CalendarOutlined />,
        type: "calendar",
        roles: [AdminRole]
    },
    {
        label: "Chat de atendimento",
        description: "Tenha uma integração de atendimento em chat na Livia",
        icon: <MessageOutlined />,
        type: "chat",
        roles: [AdminRole]
    }
    // {
    //     label: "Autenticação",
    //     description: "Integre seu assistente com sistemas de autenticação",
    //     icon: <KeyOutlined/>,
    //     type: "auth"
    // }
];

export default function Integrations({id, messageApi}) {
    const [user] = useContext(UserContext);

    return (
        <List
            grid={{
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
                xxl: 2,
            }}
            dataSource={integrations.filter(item => item?.roles.indexOf(user.roles) === -1)}
            renderItem={(item) => <List.Item>
                {item.type === 'rita' &&
                    <RitaIntegration {...item} assistantID={id}
                                     messageApi={messageApi}/>}
                {item.type === 'whatsapp' && <WhatsappIntegration
                    {...item} assistantID={id} messageApi={messageApi}
                />}
                {item.type === 'z-api' && <ZAPIIntegration
                    {...item} assistantID={id} messageApi={messageApi}
                />}
                {item.type === 'myZap' && <MyZapIntegration
                    {...item} assistantID={id} messageApi={messageApi}
                />}
                {item.type === 'agendor' && <AgendorIntegration
                    {...item} assistantID={id} messageApi={messageApi}
                />}
                {item.type === 'evolution' && <EvolutionIntegration
                    {...item} assistantID={id} messageApi={messageApi}
                />}
                {item.type === 'calendar' && <CalendarIntegration
                    {...item} assistantID={id} messageApi={messageApi}
                />}
                {item.type === 'chat' && <ChatIntegration
                    {...item} assistantID={id} messageApi={messageApi}
                />}
                {/*{item.type === 'auth' &&*/}
                {/*    <AuthIntegration {...item} assistantID={id}*/}
                {/*                     messageApi={messageApi}/>}*/}
            </List.Item>}
        />
    )
}
