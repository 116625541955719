import Fetch from "./index";

export const getChatInstance = async (assistantID) => {
    if (!assistantID)
        return Promise.reject("failed to read id");

    return Fetch("/assistant/" + assistantID + "/chat-integration")
}

export const createChatInstance = async (assistantID, body) => {
    if (!assistantID)
        return Promise.reject("failed to read id");

    const data = new FormData();
    for (const key in body) {
        data.set(key, body[key]);
    }

    return Fetch(
        "/assistant/" + assistantID + '/chat-integration/',
        {
            method: 'POST',
            body: data,
        },
    )
}

export const deleteChatInstance = async (assistantID, id) => {
    if (!assistantID)
        return Promise.reject("failed to read id");

    return Fetch(
        "/assistant/" + assistantID + '/chat-integration/' + id,
        {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const updateChatInstance = async (assistantID, id, body) => {
    if (!assistantID)
        return Promise.reject("failed to read id");

    delete body['createdAt']
    delete body['updatedAt']
    delete body['deletedAt']
    delete body['id']

    const data = new FormData();
    for (const key in body) {
        data.set(key, body[key]);
    }

    return Fetch(
        "/assistant/" + assistantID + '/chat-integration/' + id,
        {
            method: 'PUT',
            body: data,
        },
    )
}
