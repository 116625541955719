import {Form, Mentions, Select} from "antd";
import {useCallback, useMemo, useState} from "react";


const numFormFields = 4;

export default function Schedule({parameters}) {
    const [selectedValue, setSelectedValue] = useState([]);
    const available = useMemo(() =>
            parameters.properties.map(({name}) => name)
                ?.filter(item => !selectedValue.includes(item))
                ?.map(item => ({label: item, value: item})),
        [parameters, selectedValue])

    const availableArrayInteger = useMemo(() =>
            parameters.properties
                .filter(({type, items}) => type === 'array' && items?.type === 'string')
                ?.map(({name}) => ({label: name, value: name})),
        [parameters, selectedValue])

    const handleValueChange = useCallback((value) =>
            setSelectedValue(old => old?.length < numFormFields ? [...old, value] : old),
        []);


    return (
        <>
            <Form.Item label={'Horário de início'}
                       rules={[
                           {
                               required: true,
                               message: "É preciso escolher um parametro do gatilho",
                           }
                       ]}
                       name={['action', 'data', 'start']}>
                <Select placeholder={'Início'}
                        options={available}
                        onChange={handleValueChange}/>
            </Form.Item>
            <Form.Item label={'Horário de encerramento'}
                       rules={[
                           {
                               required: true,
                               message: "É preciso escolher um parametro do gatilho",
                           }
                       ]}
                       name={['action', 'data', 'end']}>
                <Select placeholder={'Fim'}
                        options={available}
                        onChange={handleValueChange}/>
            </Form.Item>
            {/*<Form.Item label={'ID de integração'}*/}
            {/*           help={"Esse id é retornado nas outras rotas, de check e list."}*/}
            {/*           name={['action', 'data', 'integrationID']}>*/}
            {/*    <Select placeholder={'ID de integração'}*/}
            {/*            options={available}*/}
            {/*            onChange={handleValueChange}/>*/}
            {/*</Form.Item>*/}
            <Form.Item label={'Convidados'}
                       help={'Você deve ter criado um parametro lista de inteiros no gatilho'}
                       name={['action', 'data', 'invites']}>
                <Select placeholder={'Seleciona a variável de convidados'}
                        options={availableArrayInteger}
                        onChange={handleValueChange}/>
            </Form.Item>
            <Form.Item label={'Assunto da reunião'}
                       name={['action', 'data', 'summary']}>
                <Mentions placeholder={'Use $ para selecionar um parametro'}
                          options={available}
                          prefix={"$"}/>
            </Form.Item>
            <Form.Item label={'Descrição'}
                       name={['action', 'data', 'description']}>
                <Mentions placeholder={'Use $ para selecionar um parametro'}
                          options={available}
                          prefix={"$"}/>
            </Form.Item>
            <Form.Item label={'Local'}
                       name={['action', 'data', 'local']}>
                <Mentions placeholder={'Use $ para selecionar um parametro'}
                          options={available}
                          prefix={"$"}/>
            </Form.Item>
        </>
    )
}
