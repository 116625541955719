import {Form, Select} from "antd";

const actions = [
    {
        label: "Assistente",
        value: "InVerbeux",
    },
    {
        label: "Chat",
        value: "InProgress",
    }
]

export default function ChangeStatus() {

    return (
        <>
            <Form.Item label={'Estado'} name={['action', 'data', 'status']}>
                <Select options={actions} placeholder={"Selecione o estado"}/>
            </Form.Item>
        </>
    )
}
