import {Form, Select} from "antd";
import ChangeStatus from "./SetLabel";

const actions = [
    {
        label: "Mudar Estado",
        value: "CHANGE_STATUS",
    }
]

export default function ChatIntegrationForm({form}) {
    const type = Form.useWatch(['action', 'type'], form);

    return (
        <>
            <Form.Item label={'Tipo de ação'} name={['action', 'type']}>
                <Select options={actions} placeholder={"Selecione o tipo de ação"}/>
            </Form.Item>
            {type === 'CHANGE_STATUS' && <ChangeStatus/>}
        </>
    )
}
