import {Button, Checkbox, Col, Divider, Form, Input, Modal, Row, Select, Space, Switch} from "antd";
import React, {useCallback, useContext, useEffect, useState} from "react";
import MessageApiContext from "../../../../../context/message-api";
import {EnterOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

const TriggerForm = ({
                         open, setOpen, assistantId, onFinish, initValues, okText = 'Criar', onSuccess = () => {
    }
                     }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const messageApi = useContext(MessageApiContext);
    // TODO: improve FormParams component to remove this properties
    const properties = Form.useWatch(['args', 'parameters', 'properties'], form)

    useEffect(() => {
        if (form && initValues)
            form.setFieldsValue(initValues);
    }, [form, initValues]);

    const finish = useCallback((values) => {
        const requiredFields = []
        values.args.parameters.type = 'object';
        if (values?.args?.parameters?.properties) {
            let newProperties = {};
            for (const value of values.args.parameters.properties) {
                newProperties[value.name] = {
                    type: value.type,
                    description: value.description,
                    enum: value.limitOptions ? value.enum : []
                }

                if (value.items)
                    newProperties[value.name].items = value.items

                if (value.required)
                    requiredFields.push(value.name);
            }
            values.args.parameters.required = requiredFields;
            values.args.parameters.properties = newProperties;
        }

        if (Object.keys(values?.args?.parameters?.properties ?? {}).length <= 0)
            delete values.args.parameters;

        setLoading(true);
        onFinish(assistantId, values)
            .then(() => {
                setOpen(false);
                form.resetFields();
                onSuccess();
            })
            .catch((err) => {
                console.error(err);
                messageApi.error("Falha ao criar gatilho.");
            }).finally(() => {
            setLoading(false);
        });
    }, [assistantId, onSuccess])

    return <Modal open={open}
                  confirmLoading={loading}
                  onOk={() => form.submit()}
                  onCancel={() => setOpen(false)}
                  okText={okText}
                  cancelText={'Cancelar'}>
        <Form onFinish={finish}
              form={form}
              layout={'vertical'}>
            <Row justify={'space-between'} gutter={[12, 12]}>
                <Col span={'20'}>
                    <Form.Item name={['args', 'name']}
                               rules={[
                                   {
                                       required: true,
                                       message: "O nome é obrigatório"
                                   },
                                   {
                                       pattern: /^[a-zA-Z0-9_-]{1,64}$/,
                                       message: 'O nome da função não pode ter espaços ou caracteres especiais',
                                   }
                               ]}
                               label={'Nome da função'}>
                        <Input placeholder={'consulta_clima'}/>
                    </Form.Item>
                </Col>
                <Col style={{marginRight: 16}}>
                    <Form.Item valuePropName="checked"
                               name={'isActive'}
                               label={'Ativo'}>
                        <Switch defaultChecked={true}/>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item tooltip={'Você deve descrever quando essa função deve ser chamada'}
                       name={['args', 'description']}
                       rules={[{required: true, message: "A descrição é obrigatório"}]}
                       label={'Descrição'}>
                <Input.TextArea placeholder={'Função para consultar o clima...'} autoSize/>
            </Form.Item>
            <Divider>Parametros</Divider>
            <FormParams properties={properties} name={['args', 'parameters', 'properties']}/>
            <Form.Item style={{display: "none"}}>
                <Button type="primary" htmlType="submit">Salvar</Button>
            </Form.Item>
        </Form>
    </Modal>
}

function FormParams(props) {
    return (
        <Form.List {...props}>
            {(fields, {add, remove}) => (<>
                {fields.map((field, index) => (<Form.Item
                    required={true}
                    style={{marginBottom: 0, width: '100%'}}
                    key={field.key}
                >
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <Form.Item
                                noStyle
                                {...field}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: "Digite um nome ou delete o parametro",
                                    },
                                    {
                                        pattern: /^[a-zA-Z0-9_-]{1,64}$/,
                                        message: 'O nome do parametro não pode ter espaços ou caracteres especiais',
                                    }
                                ]}
                                name={[field.name, 'name']}
                            >
                                <Input placeholder={'Nome da cidade'}/>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item noStyle dependencies={[[props.name, field.name, 'type']]}>
                                {({getFieldValue}) => {
                                    const typeValue = getFieldValue([...props.name, field.name, 'type']);
                                    return (
                                        <Row gutter={[12, 12]}>
                                            <Col span={typeValue === 'array' ? 12 : 24}>
                                                <Form.Item
                                                    noStyle
                                                    {...field}
                                                    name={[field.name, 'type']}
                                                    initialValue="string"
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: 'Escolha um tipo ou delete o parametro',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        popupMatchSelectWidth={false}
                                                        options={[
                                                            {label: 'Texto', value: 'string'},
                                                            {label: 'Inteiro', value: 'number'},
                                                            {label: 'Lista', value: 'array'},
                                                        ]}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {typeValue === 'array' && (
                                                <Col span={12}>
                                                    <Form.Item
                                                        noStyle
                                                        {...field}
                                                        name={[field.name, 'items', 'type']}
                                                        initialValue="string"
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: 'Escolha um tipo para a lista',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            popupMatchSelectWidth={false}
                                                            options={[
                                                                {label: 'Texto', value: 'string'},
                                                                {label: 'Inteiro', value: 'number'},
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            )}
                                        </Row>
                                    );
                                }}
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Button onClick={() => remove(field.name)} icon={<MinusCircleOutlined/>}/>
                        </Col>
                        <Col span={22}>
                            <Form.Item
                                noStyle
                                {...field}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[{
                                    required: true,
                                    whitespace: true,
                                    message: "Adicione uma descrição ou remova o campo",
                                }]}
                                name={[field.name, 'description']}
                            >
                                <Input.TextArea placeholder="Representa a cidade do qual o clima será consultado."
                                                autoSize
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                {...field}
                                noStyle
                                validateTrigger={['onChange', 'onBlur']}
                                name={[field.name, 'required']}
                                valuePropName="checked"
                            >
                                <Checkbox>Obrigatório</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                {...field}
                                noStyle
                                validateTrigger={['onChange', 'onBlur']}
                                name={[field.name, 'limitOptions']}
                                valuePropName="checked"
                            >
                                <Checkbox>Limitar Opções</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            {props?.properties?.length > 0 && Boolean(props?.properties[index]?.limitOptions) &&
                                <Form.Item
                                    {...field}
                                    noStyle
                                    rules={[{
                                        required: true,
                                        message: "É obrigatório adicionar pelo menos uma opção.",
                                    }]}
                                    validateTrigger={['onChange', 'onBlur']}
                                    name={[field.name, 'enum']}>
                                    <Select
                                        mode="tags"
                                        open={false}
                                        maxCount={20}
                                        suffixIcon={<EnterOutlined/>}
                                        placeholder="Digite uma opção e dê enter em seguida"
                                    />
                                </Form.Item>
                            }
                        </Col>
                        {index < fields.length - 1 && <Divider/>}
                    </Row>
                </Form.Item>))}
                <Form.Item style={{margin: '1rem 0'}}>
                    <Button
                        type="dashed"
                        onClick={() => {
                            add({type: "string"});
                        }}
                        icon={<PlusOutlined/>}
                    >
                        Adicionar Parametro
                    </Button>
                </Form.Item>
            </>)}
        </Form.List>
    )
}

export default TriggerForm;
